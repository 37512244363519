import { useState, useEffect, useRef, useCallback } from 'react'
import { motion } from 'framer-motion'
import { QRCodeSVG } from 'qrcode.react'
import { Loader2 } from 'lucide-react'

// Components
import { Button } from '@/components/ui/button'
import { Checkbox } from '@/components/ui/checkbox'
import { PasswordInput } from './PasswordInput'
import { FooterLinks } from './FooterLinks'
import { CookiesConsent } from './CookiesConsent'

// Assets
import { ArrowLong } from '@/assets/ArrowLong'
import { UpSeatLogo } from '@/assets/logos/upseat_logo'

// Hooks
import useScreenSize from '@/hooks/useScreenSize'
import useViewportScroll from '@/hooks/useViewportScroll'
import { useAppStore } from '@/context/useAppStore'

// Services
import { apiClient } from '@/services/axios'
import { registerUser } from '@/services/User/registerUser'
import { postLogin } from '@/services/Login/postLogin'
import { syncBooking } from '@/services/Booking/syncBooking'
import useCallRouteWithDirection from '@/hooks/useCallRouteWithDirection'

interface LoginDrawerProps {
  email: string
  phoneNumber: string
  firstName: string
  lastName: string
}

export const LoginDrawer = ({
  email,
  phoneNumber,
  firstName,
  lastName,
}: LoginDrawerProps) => {
  // Global state
  const screenSize = useScreenSize()
  const { errors, setAppStore } = useAppStore()
  const callRouteWithDirection = useCallRouteWithDirection()

  // Local state
  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [showPassword, setShowPassword] = useState(false)
  const [showConfirmPassword, setShowConfirmPassword] = useState(false)
  const [userExists, setUserExists] = useState(false)
  const [error, setError] = useState<React.ReactNode | null>()
  const [loading, setLoading] = useState(false)
  const [loadingMessage, setLoadingMessage] = useState(false)
  const [loggedIn, setLoggedIn] = useState(false)
  const [marketingOptOut, setMarketingOptOut] = useState(false)

  // Refs
  const pageRef = useRef<HTMLDivElement>(null)

  // Hooks
  useViewportScroll(pageRef)

  // Effect to check if user exists
  useEffect(() => {
    const abortController = new AbortController()
    const { signal } = abortController

    const checkUserExists = async () => {
      setLoadingMessage(true)

      apiClient
        .get(`upauth/state?email=${email}`, { signal }) // Pass the signal to the request
        .then((response) => {
          setUserExists(response.data.data.exists)
        })
        .catch((error) => {
          if (error instanceof DOMException && error.name === 'AbortError') {
            console.log('checkUserExists request aborted, ignoring error')
            return
          }

          console.error('Error checking if user exists:', error)
        })
        .finally(() => {
          setLoadingMessage(false)
        })
    }

    checkUserExists()

    return () => {
      abortController.abort() // Cleanup function
    }
  }, [email])

  // Form validation
  const isFormValid = useCallback(() => {
    if (userExists) {
      return password.length > 0
    } else {
      return (
        password.length > 0 &&
        confirmPassword.length > 0 &&
        password === confirmPassword
      )
    }
  }, [userExists, password, confirmPassword])

  // Handle login
  const handleLogin = useCallback(async () => {
    try {
      const loginResponse = await postLogin(email, password)
      await localStorage.setItem('access_token', loginResponse.access_token)
      await localStorage.setItem('refresh_token', loginResponse.refresh_token)
      setLoggedIn(true)
    } catch (error: any) {
      console.log('Error in handleLogin:', error)

      if (error.response) {
        switch (error.response.data.error) {
          case 'Account locked - Contact Administrator':
            setError(
              <p className="mb-4 text-pretty">
                Too many unsuccessful attempts. Please contact us through this{' '}
                <a
                  target="_blank"
                  className="underline font-bold text-purple4"
                  rel="noopener noreferrer"
                  href="https://54dleo9n841.typeform.com/to/zfteuTMG"
                >
                  link
                </a>
              </p>,
            )
            break
          case 'Beta access only for staff members':
            setError(error.response.data.error)
            break
          case 'invalid_grant':
            setError('Wrong email or password. Please try again.')
            break
          case 'invalid_client':
            setError('Error on the request. Please try again.')
            break
          default:
            setError(
              <p>
                {error.response.data.error_description || 'An error occurred'}
              </p>,
            )
        }
      } else if (error.message === 'Request timed out') {
        setAppStore({
          errors: {
            ...errors,
            server: true,
          },
        })
      } else {
        setError(<p>An unexpected error occurred. Please try again.</p>)
      }
    }
  }, [email, password, errors, setAppStore])

  // Handle signup
  const handleSignup = useCallback(async () => {
    try {
      // Register user, opt out of marketing by default, but allow user to opt in
      await registerUser(
        firstName,
        lastName,
        phoneNumber,
        email,
        password,
        !marketingOptOut,
      )

      // Login after successful registration
      await handleLogin()

      // Sync booking
      await syncBooking()
    } catch (error: any) {
      console.log('Error in handleSignup:', error)

      setError(
        error.response?.data?.error.code === 1
          ? '8+ characters, 1 uppercase, 1 number, 1 symbol'
          : 'An error occurred',
      )
    }
  }, [
    firstName,
    lastName,
    phoneNumber,
    email,
    password,
    marketingOptOut,
    handleLogin,
  ])

  // Handle login or signup
  const handleLoginOrSignup = useCallback(async () => {
    setLoading(true)

    try {
      if (userExists) {
        await handleLogin()
      } else {
        await handleSignup()
      }
    } finally {
      setLoading(false)
    }
  }, [userExists, handleLogin, handleSignup])

  // Handle password input change
  const handlePasswordChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setError('')
      setPassword(e.target.value)
    },
    [],
  )

  // Handle confirm password input change
  const handleConfirmPasswordChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setError('')
      setConfirmPassword(e.target.value)
    },
    [],
  )

  // Toggle password visibility
  const togglePasswordVisibility = useCallback(() => {
    setShowPassword((prev) => !prev)
  }, [])

  // Toggle confirm password visibility
  const toggleConfirmPasswordVisibility = useCallback(() => {
    setShowConfirmPassword((prev) => !prev)
  }, [])

  // Toggle marketing opt-out
  const toggleMarketingOptOut = useCallback(() => {
    setMarketingOptOut((prev) => !prev)
  }, [])

  // Ticket UI elements
  const renderTicketView = () => (
    <div className="flex flex-col items-center justify-center w-full h-[100vh] blur-[3px]">
      {/* Top Navigation */}
      <div className="absolute w-full p-2 px-4 top-0 flex justify-between">
        <div
          id="logo-top-nav-btn"
          className="[&>svg]:w-auto [&>svg]:h-[1.75rem] [&>svg]:relative [&>svg]:top-[2px] py-2 cursor-pointer"
        >
          <UpSeatLogo />
        </div>
      </div>

      {/* Main Content */}
      <div className="text-center">
        <div className="shadow-[0_0_12px_0px_#CDC3FB] rounded-[40px] bg-white rounded-[40px] relative flex flex-col items-center w-[80vw] h-[80vh] min-h-[550px] max-h-[730px] max-w-[400px] px-[5%]">
          <div className="w-full h-[75%] flex flex-col py-4">
            <div className="h-auto w-[inherit] flex flex-col justify-start">
              <div className="flex flex-col mt-4 gap-0 w-full items-center px-2 [&>div]:flex [&>div]:justify-between [&>div]:w-full">
                <div className="w-full items-center flex flex-col">
                  <div className="w-full flex justify-between py-[1.25vh] border-b border-grey2 border-solid max-w-[246px]">
                    <p className="uppercase text-purple font-light text-sm">
                      section
                    </p>
                    <p className="text-purple text-sm font-semibold">Circle</p>
                  </div>

                  <div className="w-full flex justify-between py-3 border-b border-grey2 border-solid max-w-[246px]">
                    <p className="uppercase text-purple font-light text-sm">
                      row
                    </p>
                    <p className="text-purple text-sm font-semibold">E</p>
                  </div>

                  <div className="w-full flex justify-between py-[1.25vh] max-w-[246px]">
                    <p className="uppercase text-purple font-light text-sm">
                      seat
                    </p>
                    <p className="text-purple text-sm font-semibold">12-13</p>
                  </div>
                </div>

                <div className="flex flex-col items-center gap-1 justify-center pt-2">
                  <div className="flex items-center relative rounded-[20px] p-1">
                    <div className="flex flex-col gap-4 items-center w-full [&>svg]:w-full">
                      <QRCodeSVG value={''} size={196} />
                      <p className="text-purple text-sm">1/2</p>
                      <Button
                        variant="textOnly"
                        size="small"
                        disabled
                        className="border font-bold text-sm text-purple border-purple border-2 py-2 z-[100]"
                      >
                        Manage
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="bg-purple6 absolute flex flex-col items-center justify-around w-full h-[25%] bottom-0 rounded-[0_0_35px_35px]">
            <div className="w-full h-full flex justify-center border-t border-purple2 border-dashed">
              <div className="bg-purple w-[28px] h-[28px] rotate-180 rounded-l-full absolute left-[-14px] top-[-15px]"></div>
              <div className="bg-purple w-[28px] h-[28px] rounded-l-full absolute right-[-12px] top-[-15px]"></div>
              <div className="w-full flex flex-col gap-2 items-center justify-center">
                <div className="flex flex-col gap-1 items-center">
                  <p className="text-sm text-purple leading-none capitalize text-wrap">
                    Theatre
                  </p>
                  <p className="text-[12px] text-purple leading-none capitalize text-wrap">
                    Thu 22 Aug, 7:30pm
                  </p>
                </div>
                <Button
                  disabled
                  className="w-fit text-yellow h-auto py-3 px-8 oveflow-hidden rounded-full bg-purple justify-center relative flex items-center gap-2 my-2"
                  variant="primaryDark"
                >
                  View Ticket
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )

  return (
    <>
      {renderTicketView()}

      {/* Login/Signup/Cookies Drawer */}
      {!loadingMessage && (
        <motion.div
          id="login-drawer"
          initial={{ y: 400 }}
          animate={{ y: 0 }}
          transition={{ duration: 0.3, ease: 'easeInOut' }}
          className={`fixed bottom-0 h-auto p-6 flex flex-col gap-4 bg-[linear-gradient(85.91deg,#19162C_3.34%,#BF3ED9_198.6%)] rounded-[40px_40px_0px_0px] ${screenSize.width > 768 ? 'px-[25vw]' : ''} ${screenSize.width > 1024 ? 'w-[40vw] px-[4rem]' : 'w-full'}`}
        >
          {loggedIn ? (
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 0.3, ease: 'easeInOut' }}
              className="w-full h-full text-center"
            >
              <CookiesConsent />
            </motion.div>
          ) : (
            <>
              <p className="font-bold">
                {userExists
                  ? `${firstName}, welcome back!`
                  : `${firstName}, your tickets are here!`}
              </p>

              <PasswordInput
                value={password}
                onChange={handlePasswordChange}
                userExists={userExists}
                showPassword={showPassword}
                toggleVisibility={togglePasswordVisibility}
                placeholder={
                  !userExists
                    ? 'Create password to unlock'
                    : 'Enter password to login'
                }
                error={error}
              />

              {!userExists && password.length > 0 && (
                <PasswordInput
                  value={confirmPassword}
                  confirmPassword
                  onChange={handleConfirmPasswordChange}
                  showPassword={showConfirmPassword}
                  toggleVisibility={toggleConfirmPasswordVisibility}
                  placeholder="Confirm password"
                  error={
                    confirmPassword.length && password !== confirmPassword
                      ? 'Passwords do not match.'
                      : ''
                  }
                />
              )}

              {userExists && (
                <div className="w-full flex justify-end">
                  <p
                    onClick={() =>
                      callRouteWithDirection('/forgot-password', false, 1)
                    }
                    className="relative bottom-1 mb-3 pr-2 text-sm text-right text-purple5 w-fit"
                  >{`Forgot your password?`}</p>
                </div>
              )}

              <div className="relative flex-col items-center justify-center">
                {loading ? (
                  <div className="py-4 text-center">
                    <Loader2
                      size={16}
                      className="animate-spin will-change-transform relative leading-[50px] text-center w-[20px] h-[20px] inline-block"
                    />
                  </div>
                ) : (
                  <Button
                    id="login-button"
                    variant="primaryYellow"
                    size="large"
                    disabled={!isFormValid()}
                    className="w-full py-4"
                    onClick={handleLoginOrSignup}
                  >
                    {`Continue to full access`}
                    <ArrowLong color={!isFormValid() ? '#CDC3FB' : undefined} />
                  </Button>
                )}
              </div>

              {!userExists && password.length > 0 && (
                <p className="text-sm font-light flex items-start">
                  <Checkbox
                    id="mkt-opt-out"
                    className="rounded-lg border-white border-2"
                    color="yellow"
                    variant="secondary"
                    defaultChecked={marketingOptOut}
                    onClick={toggleMarketingOptOut}
                  />
                  <label
                    htmlFor="mkt-opt-out"
                    className="text-sm cursor-pointer pl-2 peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
                  >
                    Opt-out of marketing
                  </label>
                </p>
              )}

              {!userExists && <FooterLinks />}
            </>
          )}
        </motion.div>
      )}
    </>
  )
}
