import { useEffect, useState, useCallback } from 'react'
import { Route, Routes, useLocation } from 'react-router-dom'
import { GoogleOAuthProvider } from '@react-oauth/google'
import Hotjar from '@hotjar/browser'
import SentryInitialization from './helpers/sentry'
import { cleanTokenFromLocalStorage } from './libs/utils'
import { getUser } from './services/User/getUser'
import { useAppStore } from './context/useAppStore'
import { useUserStore } from '@/context/useUserStore'
import useScreenSize from './hooks/useScreenSize'
import { useVersionChecker } from './hooks/useVersionChecker'
import { toast } from './hooks/useToast'
import NetworkStatus from './components/upseat-ui/app/networkStatus'
import ProtectedRoutes from './components/upseat-ui/app/protectedRoutes'
import PublicRoutes from './components/upseat-ui/app/publicRoutes'
import { Toaster } from './components/ui/toaster'
import { LoadingScreen } from './components/upseat-ui/loaders/LoadingScreen'
import './App.scss'

function formatUser(res: any) {
  return {
    uid: res.uid,
    username: res.email.split('@')[0],
    firstname: res.first_name,
    lastname: res.last_name,
    email: res.email,
    phoneNumber: res.phone_number,
    cookies: res.cookies,
    marketing: res.marketing,
  }
}

function App() {
  const location = useLocation()
  const screen = useScreenSize()
  const { user, setUser } = useUserStore()
  const { errors, loading, setAppStore } = useAppStore()
  const [updateAvailable, setUpdateAvailable] = useState(false)
  const [authAttempted, setAuthAttempted] = useState(false)

  useVersionChecker(setUpdateAvailable)

  const fetchUserData = useCallback(async () => {
    setAppStore({ loading: { app: true, initial: false } })
    const token = await localStorage.getItem('access_token')

    if (navigator.onLine && token && !user) {
      const userFromToken = await getUser()
        .then((res) => (res ? formatUser(res) : null))
        .catch((err) => console.log('getUser Error:', err))
        .finally(() => setAppStore({ loading: { app: false, initial: false } }))

      userFromToken ? setUser(userFromToken) : cleanTokenFromLocalStorage()
    } else {
      setAppStore({ loading: { app: false, initial: false } })
    }
  }, [setAppStore, setUser, user])

  useEffect(() => {
    if (!user && !authAttempted) {
      setAuthAttempted(true)
      fetchUserData()
    }
  }, [user, authAttempted, fetchUserData])

  useEffect(() => {
    if (updateAvailable) {
      toast({
        title: 'New version available!',
        description: 'Please refresh the page to update the app',
      })
    }
  }, [updateAvailable])

  const showErrorToast = useCallback((title: string, description: string) => {
    toast({ title, description, variant: 'destructive' })
  }, [])

  useEffect(() => {
    if (errors.server) {
      showErrorToast(
        'Server Error',
        'Something went wrong, please try again later',
      )
    }
    if (errors.network) {
      showErrorToast('Network Error', 'It looks like you are offline')
    }
  }, [errors.server, errors.network, showErrorToast])

  // Initialize Hotjar
  useEffect(() => {
    if (user?.cookies) {
      console.log('Hotjar initialized')
      Hotjar.init(parseInt(process.env.REACT_APP_HOTJAR_ID as string), 6, {
        debug: true,
      })
      Hotjar.stateChange(location.pathname)
    }
  }, [location, user])

  return (
    <div data-testid="up-app">
      <NetworkStatus />
      <Toaster />

      {!loading.app ? (
        <div className="upseat w-screen h-[100svh] bg-purple overflow-hidden flex">
          <GoogleOAuthProvider
            clientId={process.env.REACT_APP_GOOGLE_OAUTH_CLIENT_ID as string}
          >
            {user && <SentryInitialization user={user} />}
            <Routes>
              <Route
                path="*"
                element={
                  user ? <ProtectedRoutes user={user} /> : <PublicRoutes />
                }
              />
            </Routes>
          </GoogleOAuthProvider>
        </div>
      ) : (
        <LoadingScreen screen={screen} />
      )}
    </div>
  )
}

export default App
