import useEventStore from '@/context/useEventStore'
import useExistingBookingStore from '@/context/useExistingBookingStore'
import { useBasketStore } from '@/context/useBasketStore'
import { useTicketsStore } from '@/context/useTicketsStore'
import { getPlan } from '@/services/Seats/getPlan'
import { PriceBand, Seat } from '@/types'
import { Booking, Order } from '@/types/booking'
import { useCallback, useRef } from 'react'
import { useNavigate } from 'react-router-dom'

export function useSeatMap() {
  const { setMapSeats, setPriceBands, searchingSeats, setSearchingSeats } =
    useEventStore()
  const { setOrder, setBooking } = useExistingBookingStore()
  const { removeBasket } = useBasketStore()
  const { wallet } = useTicketsStore()
  const navigate = useNavigate()

  // Ref to store the AbortController instance
  const abortControllerRef = useRef<AbortController | null>(
    new AbortController(),
  )

  const getBookingFromOrder = useCallback(
    (order: Order) => {
      return wallet?.find((booking: Booking) =>
        booking.orders.some((o) => o.uid === order.uid),
      ) as Booking
    },
    [wallet],
  )

  const getSeatMap = useCallback(
    async (orderSelected: Order) => {
      // Abort the previous request if it exists
      if (abortControllerRef.current) {
        abortControllerRef.current.abort()
      }

      // Create a new AbortController for the current request
      abortControllerRef.current = new AbortController()
      const { signal } = abortControllerRef.current as AbortController

      if (!wallet || !orderSelected) {
        setSearchingSeats(false)
        navigate('/tickets')
        return
      }

      const booking = getBookingFromOrder(orderSelected)
      if (!booking) {
        setSearchingSeats(false)
        navigate('/tickets')
        return
      }

      setOrder(orderSelected)
      setBooking(booking)

      await removeBasket()

      if (wallet && orderSelected.state.upgrade.available) {
        try {
          const plan = await getPlan(orderSelected?.uid as string, signal)

          if (!plan.bands || !!plan.error) {
            console.log('Error fetching plan')
            setSearchingSeats(false)
            return
          }

          const sortedPriceBands = plan.bands
            .sort(
              (a: PriceBand, b: PriceBand) =>
                (a.upgrade_values.saved_price ?? 0) -
                (b.upgrade_values.saved_price ?? 0),
            )
            .filter(
              (band: PriceBand) =>
                band.upgrade_values.saved_price &&
                band.upgrade_values.saved_price > 0,
            )

          setPriceBands(sortedPriceBands)

          const updatedSeats = plan.seats?.map((seat: Seat) => {
            const priceBandIndex = sortedPriceBands.findIndex(
              (priceBand: PriceBand) => priceBand.uid === seat.band_uid,
            )

            return {
              ...seat,
              tier: priceBandIndex + 1,
              upgrade_price:
                sortedPriceBands[priceBandIndex]?.upgrade_values
                  .unit_upgrade_price,
              original_price:
                sortedPriceBands[priceBandIndex]?.upgrade_values
                  .unit_original_price,
              discount:
                sortedPriceBands[priceBandIndex]?.upgrade_values
                  .discount_percentage,
              saved_price:
                sortedPriceBands[priceBandIndex]?.upgrade_values.saved_price,
              number_tickets:
                sortedPriceBands[priceBandIndex]?.upgrade_values.number_tickets,
            }
          })

          setMapSeats(updatedSeats)
        } catch (error) {
          if (error instanceof DOMException && error.name === 'AbortError') {
            console.log('Request aborted, ignoring error')
            return
          }

          console.error('Error fetching seat map:', error)
          setSearchingSeats(false)
        }
      }
    },
    [
      wallet,
      getBookingFromOrder,
      setOrder,
      setBooking,
      removeBasket,
      setSearchingSeats,
      navigate,
      setPriceBands,
      setMapSeats,
    ],
  )

  return { getSeatMap, searchingSeats, setSearchingSeats }
}
